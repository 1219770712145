import clsx from "clsx";
import React, { useState } from "react";
import { Box, Button, Grid, Link, makeStyles, Typography } from "..";
import { AppImages } from "../../constants";
import { ProductInfoDialog } from "../Dialogs";

const {
  icon: { wrongIcon, correctIcon },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  healthloqWidgetBlockchainProofBox: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 20,
    width: "100%",
    maxWidth: 830,
    margin: "0 auto",
    backgroundColor: theme.palette.common.white,
    "& img": {
      marginLeft: 5,
    },
    "& p": {
      wordBreak: "break-word",
      "&>span": {
        marginRight: 5,
        fontWeight: 700,
      },
    },
    "& h5": {
      margin: "10px 0",
    },
  },
  healthloqWidgetpPoductInfoDiv: {
    "&>div": {
      "&:first-child": {
        margin: "auto 0",
        "&>*:not(:last-child)": {
          marginBottom: 20,
        },
      },
      "&>img": {
        width: "100%",
        height: "100%",
        maxWidth: 200,
        maxHeight: 200,
      },
    },
    "& a": {
      textTransform: "capitalize",
    },
  },
  productImgDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionBtns: {
    marginTop: 10,
  },
}));

export default function IntegrantBlockchainProof({
  blockchainData,
  className = "",
}) {
  const classes = useStyle();
  const [openShowProofDialog, setOpenShowProofDialog] = useState(false);

  return (
    <Box
      className={clsx(classes.healthloqWidgetBlockchainProofBox, className)}
      display="flex"
      flexDirection={"column"}
      alignItems="flex-start"
    >
      <Typography variant="h6" bold>
        Block Location
      </Typography>
      <Typography variant="body2">
        <span>Block ID:</span>
        {blockchainData?.blockAddress?.IonText}
      </Typography>
      <Typography
        variant="h5"
        display={"flex"}
        alignItems="center"
        justifyContent={"flex-start"}
      >
        Status: {blockchainData?.result ? "Verified" : "Not Verified"}
        {blockchainData?.result ? (
          <img src={correctIcon} alt="currect-icon" />
        ) : (
          <img src={wrongIcon} alt="wrong-icon" />
        )}
      </Typography>
      <Grid
        container
        spacing={1}
        className={classes.healthloqWidgetpPoductInfoDiv}
      >
        <Grid
          item
          xs={12}
          sm={blockchainData?.data?.IntegrantTypeImageUrl ? 8 : 12}
        >
          {blockchainData?.data?.type === "organization_exhibit" ? (
            <Box>
              <Typography variant="h6" bold>
                Document Information
              </Typography>
              <Typography variant="body2">
                <span>Document Name:</span>
                {blockchainData?.data?.title}
              </Typography>
              <Typography variant="body2">
                <span>Document ID:</span>
                {blockchainData?.data?.id}
              </Typography>
            </Box>
          ) : blockchainData?.data?.type === "document_hash" ? (
            <Box>
              <Typography variant="h6" bold>
                Document Information
              </Typography>
              <Typography variant="body2">
                <span>Document ID:</span>
                {blockchainData?.data?.id}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="h6" bold>
                Product Information
              </Typography>
              <Typography variant="body2">
                <span>Product Name:</span>
                {blockchainData?.data?.Title}
              </Typography>
              <Typography variant="body2">
                <span>Batch ID:</span>
                {blockchainData?.data?.ExternalId}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant="h6" bold>
              Digitally Signed By
            </Typography>
            <Typography variant="body2">
              <span>Organization Name:</span>
              <Link
                to={`/organization-detail/${blockchainData?.data?.OrganizationId ||
                  blockchainData?.data?.organization_id
                  }`}
                target="_blank"
                hyperlink
                isLink={false}
              >
                {blockchainData?.data?.OrganizationName ||
                  blockchainData?.data?.organization_name}
              </Link>
            </Typography>
            <Typography variant="body2">
              <span>Organization Id:</span>
              {blockchainData?.data?.OrganizationId ||
                blockchainData?.data?.organization_id}
            </Typography>
          </Box>
        </Grid>
        {blockchainData?.data?.IntegrantTypeImageUrl && (
          <Grid item xs={12} sm={4} className={classes.productImgDiv}>
            <img
              src={
                blockchainData?.data?.IntegrantTypeThumbnailUrl ||
                blockchainData?.data?.IntegrantTypeImageUrl
              }
              alt="product-img"
            />
          </Grid>
        )}
      </Grid>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"flex-start"}
        className={classes.actionBtns}
      >
        <Button
          variant="contained"
          primaryDarkBtn
          onClick={() => setOpenShowProofDialog(true)}
        >
          View More
        </Button>
      </Box>
      <ProductInfoDialog
        open={openShowProofDialog}
        handleClose={() => setOpenShowProofDialog(false)}
        data={blockchainData?.data}
      />
    </Box>
  );
}
