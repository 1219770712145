import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { MainHeading, Button, MuiTextfield } from "../../components/Profile";
import { updatePassword } from "../../redux/actions/profileActions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import utils from "../../utils/commonUtils";
import { SHA1 } from 'crypto-js';

export function Password(props) {
  const { match, updatePassCodeData, updatePassword, history, type } = props;
  const { is_verified } = utils.getLocalStorageItems();
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState({ password: "", confirm_password: "", otherError: "" });
  const [togglePasswordVisibility, setTogglePasswordVisibility] =
    useState(false);
  const handlePasswordVisibility = () =>
    setTogglePasswordVisibility(!togglePasswordVisibility);
  const { token } = useParams();

  const checkCompromisedPassword = async (password) => {
    const hash = SHA1(password);
    const newPassword = hash.toString();
    const prefix = newPassword.substring(0, 5);
    const suffix = newPassword.substring(5);

    try {
      const response = await fetch(`https://api.pwnedpasswords.com/range/${prefix}`);
      const data = await response.text();
      const lines = data.split('\n');
      const isPwned = lines.some(line => line.startsWith(suffix.toUpperCase())); // true if the password is compromised
      return isPwned;
    } catch (error) {
      console.error('Error checking password:', error);
      return true;
    }
  };

  const handleVerify = async () => {
    let isValid = true;
    let obj = { ...error };
    if (!password) {
      isValid = false;
      obj["password"] = "Password is a required field.";
    }
    if (password && (password?.length < 16)) {
      isValid = false;
      obj["password"] = "Password length should 16 characters long.";
    }
    if (!confirm_password) {
      isValid = false;
      obj["confirm_password"] = "Confirm password is a required field.";
    }
    if (confirm_password && password && confirm_password !== password) {
      isValid = false;
      obj["confirm_password"] = "Confirm password should be same as password.";
    }
    if (isValid && password && confirm_password && password?.length > 16) {
      const is_safe = await checkCompromisedPassword(password);
      if (is_safe) {
        isValid = false
        obj["otherError"] = "The password you chose is commonly used. Please try another password"
      }
    }
    setError(obj);
    return isValid;
  };
  const handleSubmit = async () => {
    const is_valid = await handleVerify()
    if (is_valid) {
      await updatePassword({ password, token, type })
      history.push("/verify-otp");
    }
  };
  useEffect(() => {
    if (updatePassCodeData.status === "1") {
      if (Boolean(is_verified)) {
        history.push("/profile-signup");
      } else {
        history.push("/profile-login");
      }
    }
  }, [updatePassCodeData]);
  return (
    <Grid className="ac-root">
      <Grid className="ac-content">
        <MainHeading>
          {type === "create" ? "Create" : "Reset"} Password
        </MainHeading>
        <Grid className="ac-form-div">
          <MuiTextfield
            placeholder={"Password"}
            match={match}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError({ ...error, password: "" });
            }}
            required
            autoFocus
            type={togglePasswordVisibility ? "text" : "password"}
            endIcon={
              togglePasswordVisibility ? (
                <VisibilityOff onClick={handlePasswordVisibility} />
              ) : (
                <Visibility onClick={handlePasswordVisibility} />
              )
            }
            error={Boolean(error?.password)}
            helperText={error?.password}
          />
          <MuiTextfield
            placeholder={"Confirm Password"}
            match={match}
            value={confirm_password}
            onChange={(e) => {
              setConfirm_password(e.target.value);
              setError({ ...error, confirm_password: "" });
            }}
            required
            type={togglePasswordVisibility ? "text" : "password"}
            endIcon={
              togglePasswordVisibility ? (
                <VisibilityOff onClick={handlePasswordVisibility} />
              ) : (
                <Visibility onClick={handlePasswordVisibility} />
              )
            }
            error={Boolean(error?.confirm_password)}
            helperText={error?.confirm_password}
          />
        </Grid>
      </Grid>
      <span style={{ color: "#D6513F" }}>{error.otherError}</span>
      <Grid className="ac-footer">
        <Button
          onClick={handleSubmit}
          loading={updatePassCodeData?.loading}
          profileBgBtn
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = ({ ProfileReducers: { updatePassCodeData } }) => ({
  updatePassCodeData,
});

const mapDispatchToProps = {
  updatePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
