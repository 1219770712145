import React from "react";
import { AppImages } from "../../constants";
import { Body } from "../../components/Common";

const { imageTC } = AppImages;

const TermsAndConditions = () => (
  <Body seoTitle="Terms and Conditions for a Product & Service - HealthLOQ">
    <div className="page-tc">
      <div
        className="header-tc"
        style={{ backgroundImage: "url(" + imageTC + ")" }}
      >
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="header-content d-flex align-items-end">
                <div>
                  <p className="txt-tc">Terms & Conditions</p>
                  <p className="txt-carefully">
                    Please read these terms and conditions carefully before
                    using Our Service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row no-gutters">
          <div className="col-12">
            <p className="txt-lastupdate text-right">
              Last updated: November 17, 2023
            </p>
            <br />
            <p>
              Before we get started, you should know how this document is laid
              out. Below is the full legal agreement, which is important for you
              to read, and is what will apply if we ever get into an argument
              with each other (as if we would!). Each section is followed with
              an "In Other Words" explanation which is the gist of the
              agreement, written in plain English.
            </p>
            <p>
              The following are terms of a legal agreement ("Agreement") between
              HealthLOQ LLC. ("HealthLOQ", "Our", "Us" or "We") and you ("You"
              or "Your") and applies to Your use of the Services (as defined
              below).
            </p>
            <p>
              <i>
                IMPORTANT! If You are being directed to this Agreement because
                you are purchasing a product or service from someone other than
                HealthLOQ that happens to include services from HealthLOQ,
                please read this first.
              </i>
            </p>
            <br />
            <h1>INTRODUCTION</h1>
            <ul>
              <li>
                Description of the Services. HealthLOQ operates a managed
                blockchain service known as 'HealthLOQ' (the "HealthLOQ
                Service"). The HealthLOQ Service enables You to publish
                information to a blockchain, link to information provided by
                Your supply chain partners, and present this information to
                customers, regulators, or other interested parties. The services
                offered by HealthLOQ include: (i) the HealthLOQ Service; (ii)
                any HealthLOQ-branded software; (iii) those offered on any
                HealthLOQ-branded URL, including www.HealthLOQ.com (the
                "Website"); and (iv) any other features, content, or
                applications offered or operated from time to time by HealthLOQ
                in connection with HealthLOQ's business (collectively,
                "Services").
              </li>
              <li>
                Scope of this Agreement. This Agreement constitutes legally
                binding terms and applies to Your use of the Services regardless
                of the type of computer, tablet, smartphone or other device
                ("Device") used to access them unless such services post a
                different terms of use, terms of service or end user license
                agreement, in which case that agreement ("Other Terms") shall
                instead govern. By accessing and/or using any of the Services,
                You agree to be bound by this Agreement (or if applicable, the
                Other Terms), whether You are a "Visitor" (which means that You
                simply browse the Website, or otherwise use the Services without
                being registered) or You are a "Customer" (which means that You
                have registered with HealthLOQ). The term "User" refers to a
                Visitor or a Customer. You are authorized to use the Services
                (regardless of whether Your access or use is intended) only if
                You agree to abide by all applicable laws, rules and regulations
                ("Applicable Law") and the terms of this Agreement and any
                applicable Other Terms. In addition, as a condition to becoming
                a Customer and/or making use of the Services, You must indicate
                Your acceptance of this Agreement during the registration
                process. Only after acceptance may You create Your Account (as
                defined below) in accordance with this Agreement.
              </li>
              <li>
                Additional Terms. In some instances, both this Agreement and
                separate guidelines, rules, or terms of service, use or sale
                setting forth additional or different terms and/or conditions
                will apply to Your use of the Services or to a service or
                product offered via the Services (in each such instance, and
                collectively, "Additional Terms"). To the extent there is a
                conflict between this Agreement and any Additional Terms, the
                Additional Terms will control unless the Additional Terms
                expressly state otherwise. Please also review the terms of the
                Privacy Policy (as defined below), as they also apply.
              </li>
              <li>
                Acceptance. By accessing, receiving, and/or using the Services,
                You agree, without limitation or qualification, to be bound by
                and to comply with this Agreement, Other Terms, Additional Terms
                and Applicable Laws. If You do not agree to be bound by this
                Agreement (and any applicable Other Terms or Additional Terms)
                and to abide by all Applicable Laws, then You are not authorized
                to use the applicable Services and must immediately discontinue
                use and permanently delete all software components in your
                possession that were provided by HealthLOQ in relation to the
                Services.
              </li>
              <li>
                Use of the Services on Behalf of an Organization. If You are
                using the Services on behalf of an organization: (i) You are
                agreeing to this Agreement for that organization and represent
                and warrant to HealthLOQ that You have the authority to bind
                that organization to this Agreement (in which event, "You" and
                "Your" will refer to that organization) unless that organization
                has a separate contract in effect with Us, in which event the
                terms of that contract will govern Your use of the Services;
                (ii) You may use the Services only in compliance with this
                Agreement and only if You have the power to form a contract with
                HealthLOQ and are not barred under any Applicable Laws from
                doing so; and (iii) Your use of the Services shall be governed
                by this Agreement, except where any separate agreement made
                between You and Us governing Your Account expressly supersedes
                or prevails over this Agreement. If, after acceptance of this
                Agreement, We find that You do not have the legal authority to
                bind such organization, You will be personally responsible for
                the obligations contained in this Agreement.
              </li>
              <li>
                Location of Provision of the Services and Location of Data.
                While HealthLOQ operates from the United States of America, the
                Services, information that we collect and Account Data (as
                defined below) are transferred to and hosted in other
                jurisdictions. As part of Your use of the Services, You consent
                to the transfer of Account Data, personal information and Your
                Content (as defined below) outside of the United States of
                America . Account Data, Your information and Your Content may
                become, during the period that they are hosted outside the
                United States of America, subject to the laws of the
                jurisdiction in which the servers hosting them reside and/or to
                the terms of agreements respecting the hosting of data on such
                servers. Although We have made reasonable efforts to verify that
                Our agreements with such server providers are reasonably
                protective of Your data, We do not accept liability for any acts
                or omissions of third parties in relation to such servers and
                the data stored on them, and You release Us from all liability
                for any governmental or third party action taken in such
                jurisdictions with respect to such data and/or the servers on
                which such data resides. HealthLOQ does not represent that
                Content (as defined below) or other materials presented on or
                through the Services are appropriate or available for use in
                other locations or jurisdictions. If You access the Services
                from a jurisdiction other than the United States of America, You
                agree that You do so on Your own initiative, and are responsible
                for compliance with local laws.
              </li>
            </ul>
            <p className="mb-0">In other words,</p>
            <p>
              This section introduces the core concepts of our Terms of Service,
              including a brief description of HealthLOQ's product and
              definitions of certain terms used in the Agreement ("device,"
              "user," etc.). We state that, by using HealthLOQ, you implicitly
              accept these Terms of Service and any other terms provided to you
              for specific products and services. You agree you have your
              organization's permission to use our products and services.
              Finally, we clarify that we operate in the United States of
              America but information and data transferred through and stored
              within our system may be hosted in or transmitted to or from other
              jurisdictions, and may be subject to events beyond our control.
            </p>
            <br />
            <h1>INTERPRETATION</h1>
            <ul>
              <li>
                Eligibility. Use of the Services is void where prohibited. By
                using the Services, You represent and warrant that: (a) all
                registration information You submit to HealthLOQ is truthful and
                accurate; (b) You will maintain the accuracy of such
                information; and (c) Your use of the Services does not violate
                any Applicable Law.
              </li>
              <li>
                Modification of this Agreement. HealthLOQ, in its sole and
                absolute discretion, may change or modify this Agreement and any
                of the Other Terms or Additional Terms, at any time, and such
                changes or modifications shall be effective immediately upon
                posting to the Website. You acknowledge and agree that: (i)
                HealthLOQ may notify You of such changes or modifications by
                posting them to the Website and/or the HealthLOQ Service
                (including, without limitation, by notification through the
                HealthLOQ Service's sign-in processes) or by email; and (ii)
                Your use of the Services after such changes or modifications
                have been made (as indicated by the "Last Updated" date at the
                top of this page) shall constitute Your acceptance of this
                Agreement as last revised. If You do not agree to such amended
                or modified Agreement, then You must immediately stop using the
                Services and terminate this Agreement in accordance with Section
                16.
              </li>
              <li>
                Changes to the Services. We continually update and test various
                aspects of the Services, including features, functions and user
                interfaces. We reserve the right to, and by using the Services
                You agree that We may, include You in or exclude You from these
                tests without notice. We reserve the right in Our sole and
                absolute discretion to make changes from time to time and
                without notice in how We offer and operate the Services.
                HealthLOQ reserves the right to modify, suspend or terminate the
                Services (or any part thereof), either temporarily or
                permanently, at any time or from time to time, with or without
                prior notice or liability to You. Without limiting the
                foregoing, HealthLOQ may provide notice of any such changes to
                the Services by posting them on the Website and/or via the
                Services. HealthLOQ reserves the right, in its sole discretion,
                to make necessary unscheduled deployments of changes, updates or
                enhancements to the Services at any time. We may add or remove
                functionalities or features, and We may suspend or stop a
                Service altogether. You agree that HealthLOQ shall not be liable
                to You or any party for any modification or cessation of the
                Services. You acknowledge that HealthLOQ has no express or
                implied obligation to provide, or continue to provide, the
                Services, or any part thereof, now or in the future; and in
                addition, HealthLOQ may at any time, upon prior notice and/or as
                required by Applicable Law, institute or revise fees for the
                Services. HealthLOQ reserves the right to modify or replace any
                of its policies and practices related to the Services. To the
                extent that any of the foregoing requires a modification of this
                Agreement, such modification will be made in accordance with
                Section 2.2 above.
              </li>
            </ul>
            <p className="mb-0">In other words,</p>
            <p>
              Consider this section a heads-up that we'll update and improve our
              product over time, and will modify these Terms of Service
              accordingly. Expect your experience with HealthLOQ to change and
              know that we'll do our best to notify you when such changes occur
            </p>
            <br />
            <h1>OWNERSHIP AND RESERVATION OF RIGHTS</h1>
            <p>
              All trademarks, service marks, patents, copyrights, trade secrets
              and other intellectual property or proprietary rights in or
              related to the Services are and will remain the exclusive property
              of HealthLOQ or its licensors, whether or not specifically
              recognized or perfected under local law. You will not acquire any
              rights in the Services except the limited use right specified in
              this Agreement. The Services are confidential and proprietary
              information of HealthLOQ or its licensors. You agree to take
              adequate steps to protect the Services from unauthorized
              disclosure or use. The arrangements under this Agreement are
              non-exclusive. HealthLOQ may represent, perform services for, and
              contract with as many additional clients, persons, or companies as
              HealthLOQ, in their sole discretion, sees fit.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              HealthLOQ retains ownership of our intellectual property even
              though you'll become aware of it and have exposure to it during
              your use of our product.
            </p>
            <br />
            <h1>THE SERVICES</h1>
            <ul>
              <li>
                Rights to use the Services. Provided You comply at all times
                with this Agreement, pay all applicable fees, and otherwise
                remain subject to the terms and conditions of this Agreement,
                HealthLOQ hereby grants You, and You hereby accept from
                HealthLOQ, a personal, non-exclusive, non-assignable,
                non-transferable, revocable, limited right to (i) access and use
                the Services; (ii) install Components (as defined below) on Your
                Environment (as defined below); and (iii) to use Components for
                the sole purpose of enabling You to use the Services in the
                manner permitted by this Agreement. For greater certainty, you
                may use the Services and Components only in the context of
                publishing information to the blockchain and associated
                databases pertaining to Your products and supply chain, unless
                You use the Services and Components to provide managed
                blockchain services to a third party, in which case You are
                solely responsible for such relationship, and will indemnify,
                defend and hold HealthLOQ harmless from and against any claims
                made by such third party in relation to Your services, including
                the Services and Components used by You to provide the services.
                Your use of the Services and Components must be in strict
                compliance with this Agreement and all Applicable Laws.
              </li>
              <li>
                Access to Your Environment. You are wholly responsible for Your
                Account Content (including, but not limited to, the Documents
                attached to Your Account) for assigning security and access
                rights and vetting the content of all information for veracity.
                We do not guarantee that errors or irregularities will not
                occur, or that errors or irregularities will be detected should
                they occur. You are fully and solely responsible for applying
                independent business judgment with respect to the Services and
                Your Account, to make implementation decisions, if any, and to
                determine further courses of action with respect to any matters
                addressed in the Services in relation to Your Account.
              </li>
              <li>
                Components. For greater certainty, all Components are part of
                the Services. All Components are licensed, not sold. Unless We
                notify You otherwise, the Components license ends when Your
                access to the Services ends. You must then promptly uninstall
                and permanently delete the Components, or We may disable them.
                You must not work around any technical limitations in the
                Components. When You install and use the Components, We may
                automatically check Your version of the Components. You
                understand that the Components are evolving. As a result, We may
                require You to accept updates to the Components that You have
                installed. We may also automatically download to Your systems
                and Devices new versions of the Components, and by using the
                Services and agreeing to this Agreement, You consent to such
                automatic updates and downloads. You acknowledge and agree that
                We may update the Components with or without notifying You. You
                may need to update third party software from time to time in
                order to use the Components. If at any time You wish to revoke
                Your consent to such automatic updates and downloads, You may do
                so at any time by contacting Us. However, if You do so, We may
                terminate Your access to the Services, as provision of the
                Services may be dependent upon Our Users having the most up to
                date versions of Components.
              </li>
              <li>
                Your Account, Password, and Security. In order to access and use
                the HealthLOQ Services, We require You to open an account with
                HealthLOQ ("Account"). Opening an Account requires You to
                complete an online registration process by providing Us with
                current, complete and accurate information as prompted by Us
                during the registration process. You represent and warrant to Us
                that all information that You submit when You register for Your
                Account is accurate, current and complete, and that You will
                keep Your Account information accurate, current and complete.
                You may be required to choose a password for Your Account. You
                are entirely responsible for maintaining the confidentiality of
                Your password and Account. Furthermore, You are entirely
                responsible for any and all activities that occur under Your
                Account. You agree to notify HealthLOQ immediately of any
                unauthorized use of Your Account or any other breach of
                security. HealthLOQ will not be liable for any loss that You may
                incur as a result of someone else using Your password or
                Account, either with or without Your knowledge. However, You
                could be held liable for losses incurred by HealthLOQ or another
                party due to someone else using Your Account or password. The
                foregoing liability provisions shall survive the termination or
                expiration of this Agreement. You may not use anyone else's
                Account at any time without the permission of the Account
                holder.
              </li>
              <li>
                Acceptable Use. You shall not use the Services in any fashion
                except as expressly permitted by this Agreement. Without
                limiting the generality of the foregoing, You shall not, and
                shall not encourage or assist any third party to, directly or
                indirectly, do any of the following acts:s (i) sell, reproduce,
                modify or attempt to modify the Services or any of HealthLOQ's
                Content (as de ned below) in any way; (ii) reverse engineer,
                disassemble or decompile any software used to provide or access
                the Services (including, without limitation, the HealthLOQ
                Service and/or Components), or attempt to discover or recreate
                the source code used to provide or access the Services; (iii)
                use any of the Services in any manner or for any purpose other
                than as expressly permitted by this Agreement, Other Terms or
                Additional Terms; (iv) sell, lend, rent, resell, lease,
                sublicense or otherwise transfer any of the rights granted to
                You with respect to the Services to any third party; (v) remove,
                obscure or alter any proprietary rights notice pertaining to the
                Services; (vi) access or use the Services in a way intended to
                improperly avoid incurring fees or exceeding usage limits or
                quotas; (vii) use the Services to engage in any unlawful or
                fraudulent activity or perpetrate a hoax or engage in phishing
                schemes or forgery or other similar falsification or
                manipulation of data; (viii) use the Services to post any
                inappropriate Content, such as Content: (1) containing unlawful,
                defamatory, threatening, pornographic, abusive, libelous or
                otherwise objectionable material of any kind or nature, (2)
                containing any material that encourages conduct that could
                constitute a criminal offense, or (3) that violates the
                intellectual property rights or rights to the publicity or
                privacy of others; (ix) interfere with or disrupt servers or
                networks used by HealthLOQ to provide the Services or used by
                other Users to access the Services, or violate any third party
                regulations, policies or procedures of such servers or networks
                or harass or interfere with another User's full use and
                enjoyment of any Software or the Services; (x) cause, in
                HealthLOQ's sole discretion, inordinate burden on the Services
                or HealthLOQ's system resources or capacity; (xi) modify,
                translate, or create derivative works based on any portion of
                the Services or any HealthLOQ Content; (xii) access or use the
                Services in order to build a competitive product or Services or
                copy any features or functions of the Services; (xiii)
                circumvent or modify, attempt to circumvent or modify, or
                encourage or assist any other party in circumventing or
                modifying any security technology or software that is part of
                the Services; (xiv) impersonate or attempt to impersonate
                HealthLOQ or Our employees (including, without limitation, the
                use of email addresses associated with any of the foregoing);
                (xv) use the Account, username, or password of another User of
                the Services at any time; or (xvi) violate any Applicable Law.
                You acknowledge and additionally agree that We reserve the right
                to stop any conduct that restricts or inhibits other Users of
                the Services from fully utilizing and enjoying the Services and,
                further, We reserve the right to act to delete any and all
                Content (as de ned below) that contravenes any of this Agreement
                or which may otherwise have a negative impact on the safe,
                respectful, legal, friendly and courteous operation of the
                Services.
              </li>
            </ul>
            <p className="mb-0">In other words,</p>
            <p>
              In this rather lengthy section, we outline the various components
              and features of our product and explain the terms according to
              which you can access and use them. We go into excruciating detail
              about the many nefarious things we prohibit you from doing with
              our product, such as reverse-engineering our software or storing
              defamatory, threatening, or otherwise unlawful content. Long story
              short: Take responsibility for your stuff and what you do, and
              please use our product for good, not evil. Thank you!
            </p>
            <br />
            <h1>FEES AND TAXES</h1>
            <ul>
              <li>
                In consideration for providing the Services to You, You shall
                pay Us in accordance with Our fee schedule and fee policies that
                are applicable to the Services and that are in effect at the
                time that you purchase the Services, unless otherwise agreed
                upon by You and Us in writing and in advance. Fees are based on
                Services purchased and not actual usage. Payment obligations are
                non-cancellable and fees paid are non-refundable; for greater
                certainty, any fees that have been prepaid by You for Services
                that are unused due to any early termination of this Agreement
                shall not be refundable. The base subscription fee for the
                Services is payable in advance, and any billable items over
                what's included in the subscription are payable in arrears.
              </li>
              <li>
                Where You pay fees by means of a credit card, You authorize Us
                to automatically charge You for any and all fees incurred by You
                for Services. If Your credit card is rejected for any reason,
                You will be responsible for any fees and charges associated with
                such rejection. The foregoing shall not limit Our ability to
                exercise any rights available to it in law or equity respecting
                the collection of any amounts payable hereunder.
              </li>
              <li>
                If You fail to pay any amount due under this Agreement within
                thirty (30) days of such payment becoming due and payable, in
                addition to any other rights and remedies available to Us, We
                shall be entitled to charge interest on all outstanding amounts
                at the lesser of 1.5% per month or the maximum rate permitted by
                law, such interest commencing as of the due date for such
                payment. You shall also be responsible for paying for all
                reasonable fees and costs incurred by Us, including legal fees,
                in collecting any overdue amounts or enforcing any provision of
                this Agreement.
              </li>
              <li>
                You are responsible for, and shall pay all taxes relating to
                this Agreement, excluding any taxes based on Our net income.
                Unless otherwise indicated, all amounts payable by You under
                this Agreement are exclusive of any tax, duty, levy, or similar
                government charge that may be assessed by any jurisdiction,
                whether based on gross revenue, the delivery, possession or use
                of the Services, the execution of this Agreement or otherwise.
                If You are required to withhold any taxes from payments owed
                under this Agreement, the amount of payment due shall
                automatically be increased to offset such tax, so that the
                amount actually remitted to Us shall equal the amount invoiced
                or otherwise due.
              </li>
            </ul>
            <p className="mb-0">In other words,</p>
            <p>
              Though we'd love to provide our customers with our product for
              free, we're running a business, so you're going to have to pay us
              to use it. If you don't pay us on time, you'll have to pay us a
              bit extra for the hassle. Also, you have to pay any applicable
              taxes, or the government will be mad at you and us.
            </p>
            <br />
            <h1>THIRD PARTY TECHNOLOGY</h1>
            <p>
              The Services may contain features or functionality designed to
              interoperate and/or integrate with software, applications or
              services that are provided by parties other than HealthLOQ or You
              ("Third Party Services"). To use such features, You may be
              required to obtain access to such Third Party Services from their
              providers (which may require You to pay fees to the provider of
              such Third Party Services). We do not endorse and are not
              responsible or liable for the products or services provided by
              such third parties. We are not responsible for the operation or
              functionality of any Third Party Services. You are solely
              responsible for Your use of any Third Party Services, and for
              compliance with any terms and conditions applicable to such Third
              Party Services. ANY THIRD PARTY SERVICES DOWNLOADED OR OTHERWISE
              OBTAINED IN CONNECTION WITH YOUR USE OF THE SERVICES IS DONE SO AT
              YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE
              FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH
              RESPECT TO ANY DAMAGE TO YOUR SYSTEMS, DEVICES, NETWORKS, INTERNET
              ACCESS, OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
              THIRD PARTY SERVICE. If the provider of any such Third Party
              Services ceases to make the Third Party Services available for
              interoperation with the corresponding Services features or
              functionality, We may cease providing such features or
              functionality
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              Certain third-party technologies integrate into our system. We
              must acknowledge the possibility that such third-party
              technologies could experience technical problems or outages, which
              could compromise performance of certain features of our product.
              Generally, those issues will need to be taken care of by you, not
              us, since you have the relationship with the third-party
              technology providers.
            </p>
            <br />
            <h1>SUPPORT</h1>
            <p>
              If You need assistance with Your Account or the Services, please
              visit the support portions of the Website and/or the Service.
              There You will find the answers to many frequently asked questions
              and information on reaching a support representative. In certain
              instances, We may best be able to assist You by using a remote
              access support tool through which We have full access to your
              Devices and Your Accounts. If You do not want Us to have this
              access, You should not consent to support through the remote
              access support tool, and We will assist You through other means.
              Although We will make commercially reasonable efforts to resolve
              any issues You may have, We make no guarantees whatsoever with
              respect to the provision of a solution, and the warranty
              disclaimer in Section 13 applies to all support that we provide.
              In the event of any conflict between this Agreement and
              information provided by Our customer service representatives or
              other portions of the Website, this Agreement will control.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              We offer various support options if you need help setting up,
              troubleshooting or making full use of our product. You can contact
              HealthLOQ support through our website, by email, or by phone, and
              can access support documentation on our website. Customer support
              is a top priority for HealthLOQ, and we'll always try our best to
              help you.
            </p>
            <br />
            <h1>ACCOUNT DATA AND METADATA</h1>
            <ul>
              <li>
                Account Data. As between the parties, You retain all right,
                title and interest in and to Account Data (as defined below) and
                We only have and obtain the rights to Account Data that are set
                out in this Agreement. In this Agreement, "Account Data" means
                (i) data in respect of Your Account (including, Your
                Environment) provided by You to Us during the course of
                accessing and using the Services; and/or (ii) data or
                information that is generated by or derived from Your use of the
                Services (including, but not limited to, through the use of
                Components); and/or (iii) data or information that is generated
                or derived from Our access to Your Account (including, without
                limitation, the systems or Devices connected to Your
                Environment).
              </li>
              <li>
                Metadata. HealthLOQ is continuously looking for ways to optimize
                and improve the Services and Our business generally. Creating
                and generating Metadata (as defined below) helps Us in that
                regard. We will, from time to time, create anonymous data and
                records derived from or generated by Account Data (collectively,
                "Metadata"). By using the Services, You hereby grant to Us a
                world-wide, fully-paid, royalty-free, perpetual, unrestricted,
                transferable, sub-licensable, right and license to use Account
                Data for the purposes of creating Metadata. We exclusively own
                all rights, including, but not limited to, intellectual property
                rights, in and to all Metadata and We are entitled to the
                unrestricted use and dissemination of all Metadata for any
                purpose (commercial or otherwise).
              </li>
            </ul>
            <p className="mb-0">In other words,</p>
            <p>
              In delivering our Services, we'll be collecting data about the
              blockchain activities that you participate in. You own this data
              but we retain the right to access and use it. We also collect
              anonymized metadata generated by your use of our product, which we
              may use to inform development of future products and services. By
              "anonymized," we mean that this data will never be identifiable as
              yours
            </p>
            <br />
            <h1>CONTENT</h1>
            <ul>
              <li>
                Your Content. HealthLOQ does not claim any ownership rights in
                the text, files, documents, reports, results, information,
                bulletins, images, logos, photos, video, works, works of
                authorship, applications, or any other materials (collectively,
                "Content") that You transmit, submit, display or publish
                ("post") on, through or in connection with the Services. After
                posting Your Content on, through or in connection with the
                Services, You continue to retain any such rights that You may
                have in Your Content, subject to the limited license granted by
                You in this Agreement. By posting any Content on, through or in
                connection with the Services, You hereby grant to HealthLOQ
                non-exclusive, transferrable, sublicensable, royalty-free, fully
                paid, perpetual, worldwide limited license to use, modify,
                delete from, add to, combine with other content, publicly
                display, reproduce, transmit, and distribute such Content solely
                on, through or in connection with the Services and solely for
                the purpose of making the Services available to You and Users.
                You represent and warrant that you are the developer, creator
                and controller of the documentation posted on, through or in
                connection with the Services, or otherwise have the right to
                grant the license set forth in this Section 9(a); that posting
                Your Content on, through or in connection with the Services does
                not knowingly violate the privacy rights, publicity rights,
                copyrights, contract rights, any other recognized rights of any
                third party; and that no fees or payments of any kinds shall be
                due by HealthLOQ to any organization for the distribution of
                Your Content as contemplated by this Agreement and and You agree
                to pay for all royalties, fees, and any other monies owing to
                any party by reason of the use of any Your Content. You agree to
                pay for all royalties, fees, and any other monies owing to any
                party by reason of the use of any Content posted by You on or
                through the Services.
              </li>
              <li>
                HealthLOQ's Responsibility for Content. HealthLOQ may delete any
                Content (including, but not limited to, Your Content) that in
                the sole judgment of HealthLOQ violates this Agreement or which
                may be offensive, illegal or violate the rights of any party, or
                harm or threaten the safety of any party. HealthLOQ assumes no
                responsibility for monitoring the Services for inappropriate
                Content or conduct. If at any time HealthLOQ chooses, in its
                sole discretion, to monitor the Services, HealthLOQ nonetheless
                assumes no responsibility for the Content, no obligation to
                modify or remove any inappropriate Content, and no
                responsibility for the conduct of the party submitting such
                Content. HealthLOQ cannot, does not and will not guarantee the
                accuracy, completeness and quality of any Content appearing on,
                transmitted through, or otherwise made available by, the
                Services, including, but not limited to, any HealthLOQ Content
                or User Content. You acknowledge and agree that reliance on any
                Content accessible through the Services is solely at Your own
                risk. WE WILL NOT BE LIABLE TO YOU OR ANY OTHER PERSON FOR THE
                USE, MISUSE OR RELIANCE ON ANY CONTENT.
              </li>
            </ul>
            <p className="mb-0">In other words,</p>
            <p>
              You own whatever content you transmit, submit, display, or publish
              on or through our product. We're not responsible for your content,
              but we do have a free and unlimited license to use or republish it
              (just so you and users can use our product). We hold the right to
              delete content you've transmitted, submitted, displayed, or
              published that violates our Terms of Service.
            </p>
            <br />
            <h1>CONFIDENTIALITY</h1>
            <p>
              Information or data that You disclose or make available to Us
              (whether through the Services or otherwise) is: (a) personal
              information that is subject to the Privacy Policy (b) explicitly
              subject to a written non-disclosure agreement that We have entered
              into with You, or (c) usernames, passwords or other credentials
              for Your Environment that You provide or make available to Us
              through the Services, will be treated as con dential - all other
              information or data you choose to disclose or make available to us
              will be treated as non-confidential, regardless of whether You
              mark it as "confidential," "proprietary," or the like. We maintain
              appropriate technical and organizational measures, internal
              controls, and data security routines intended to protect the data
              provided by You to Us during the course of accessing and using the
              Services against accidental loss or change, unauthorized
              disclosure or access, or unlawful destruction. You acknowledge and
              agree that, notwithstanding such measures, use of or connection to
              the Internet provides the opportunity for unauthorized third
              parties to circumvent such precautions and illegally gain access
              to the Services or Account Data. Therefore, We cannot, and shall
              not be liable to You or any other party for any unauthorized
              disclosure or access, or use of Account Data or Your Content
              except where We are grossly negligent.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              Any non-personal information made available to us through your use
              of our product falls outside the Privacy Policy, and we'll only
              treat certain obvious things as being con dential. We do just
              about everything humanly (and technically) possible to protect
              your information, but if someone illegally hacks our system and
              steals that data, we can't be held responsible.
            </p>
            <br />
            <h1>FEEDBACK</h1>
            <p>
              We welcome Your feedback and comments regarding the Services. By
              submitting or providing Us with comments, messages, suggestions,
              ideas, concepts, feedback or other information about the Services,
              HealthLOQ and/or its operations (collectively, "Submissions") You
              thereby and hereby: (a) represent and warrant that none of the
              Submissions are confidential or proprietary to You or to any other
              party; (b) represent and warrant that none of the Submissions
              breach any agreement to which You are a party; and (c) grant
              HealthLOQ an exclusive fully paid-up, royalty-free, perpetual,
              irrevocable, unrestricted, transferable, sub-licensable, worldwide
              right and license to use, share and commercialize Your Submissions
              in any way and for any purpose. You also waive in favor of
              HealthLOQ and its successors and assigns any and all of Your moral
              rights in and to all Submissions. These rights survive this
              Agreement.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              We want feedback about your experience with HealthLOQ. We invite
              you to be vocal, candid, and detailed when providing feedback. But
              don't give us feedback that contains anything you want to keep
              secret, because we're going to use it to improve our services.
            </p>
            <br />
            <h1>TEST OR "BETA" FEATURES</h1>
            <p>
              From time to time, We may offer new "beta" features or tools for
              the Services for You to evaluate. Such features or tools are
              offered solely for experimental and evaluation purposes and
              without any warranty or condition of any kind, and may be modified
              or discontinued at any time at Our sole discretion.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              Our product is always under development. You may experience bugs,
              notice imperfections, or encounter features in "beta" development.
              When you do, we'd love to hear about your experience.
            </p>
            <br />
            <h1>WARRANTY DISCLAIMER</h1>
            <p>
              TO THE MAXIMUM EXTENT PERMISSIBLE BY LAW, THE SERVICES (INCLUDING,
              BUT NOT LIMITED TO, YOUR ACCESS TO AND USE OF THE SERVICES) ARE
              PROVIDED "AS IS", "AS AVAILABLE" AND "WITH ALL FAULTS". HEALTHLOQ
              MAKES NO REPRESENTATIONS OR WARRANTIES, AND THERE ARE NO
              CONDITIONS, ENDORSEMENTS, UNDERTAKINGS, GUARANTEES,
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR
              IMPLIED, (INCLUDING WITHOUT LIMITATION ANY EXPRESS OR IMPLIED
              WARRANTIES OR CONDITIONS OF QUALITY, PERFORMANCE, RESULTS, FITNESS
              FOR A PARTICULAR PURPOSE, MERCHANTABILITY, MERCHANTABLE QUALITY,
              DURABILITY, TITLE, NON-INFRINGEMENT OR ARISING BY STATUTE OR
              OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF THE
              TRADE) AS TO, ARISING OUT OF OR RELATED TO THE FOLLOWING: (I) THIS
              AGREEMENT; (II) THE SERVICES (INCLUDING, WITHOUT LIMITATION, (A)
              THE PERFORMANCE OF THE SERVICES OR ITS FAILURE TO PERFORM, (B) THE
              AVAILABILITY OR RELIABILITY OF THE SERVICES, (C) THE FUNCTIONS,
              CONTENT AND INFORMATION MADE ACCESSIBLE OR AVAILABLE ON OR THROUGH
              THE SERVICES, AND (D) COMPONENTS); AND/OR (III) SECURITY
              ASSOCIATED WITH THE TRANSMISSION OF INFORMATION OR DATA
              TRANSMITTED TO OR FROM HEALTHLOQ, YOU OR OTHERS VIA THE SERVICES.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              HealthLOQ makes no promises or guarantees about our product's
              performance or reliability. We want to set your expectations
              accurately out of the gate.
            </p>
            <br />
            <h1>INDEMNITY</h1>
            <p>
              At HealthLOQ's request, You will defend, hold harmless, and
              indemnify HealthLOQ and its directors, officers, employees,
              agents, subsidiaries, affiliates and each of their successors from
              and against all third party claims, actions, demands, proceedings,
              damages, costs and liabilities of any kind (collectively,
              "Claims") to the extent that the Claims arise out of or relate to
              Your use of the Services, Your Content or Your breach of Your
              warranties or obligations under this Agreement.
            </p>
            <p>
              At Your request, HealthLOQ will defend, hold harmless and
              indemnify You and your directors, officers, employees, agents and
              each of their successors from and against all third party claims,
              actions, demands, proceedings, damages, costs and liabilities of
              any kind (collectively " Claims") to the extent that the Claims
              arise out of or relate to the failure of HealthLOQ Services and/or
              products they provide under this Agreement or arising out of or
              relating to their breach of the terms and conditions of this
              Agreement.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              Let's say (and we're talking hypothetically here) you or we made a
              boo boo that got a third party angry at us or you. We gotta have
              each other's back if that happens.
            </p>
            <br />
            <h1>LIMITATION OF LIABILITY</h1>
            <p>
              UNDER NO CIRCUMSTANCES WILL HEALTHLOQ AND/OR ITS LICENSORS OR
              REPRESENTATIVES (INCLUDING DIRECTORS, OFFICERS AND EMPLOYEES) BE
              LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, SPECIAL, PUNITIVE OR
              INCIDENTAL DAMAGES, WHETHER FORESEEABLE OR UNFORESEEABLE,
              (INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR LOSS OF DATA, GOODWILL,
              PROFITS, LOSS OF BUSINESS REVENUE OR EARNINGS, DAMAGES CAUSED BY
              DELAYS, FAILURE TO REALIZE EXPECTED SAVINGS, OR USE OF THE
              SERVICES, INTERRUPTION IN USE OR AVAILABILITY OF DATA, STOPPAGE OR
              OTHER WORK OR IMPAIRMENT OF OTHER ASSETS), ARISING OUT OF THIS
              AGREEMENT, INCLUDING BREACH OR FAILURE OF EXPRESS OR IMPLIED
              WARRANTY, BREACH OF CONTRACT, MISREPRESENTATION, NEGLIGENCE,
              STRICT LIABILITY IN TORT OR OTHERWISE, EXCEPT ONLY IN THE CASE OF
              PERSONAL INJURY WHERE AND TO THE LIMITED EXTENT THAT APPLICABLE
              LAW REQUIRES SUCH LIABILITY. SOME JURISDICTIONS DO NOT ALLOW THE
              LIMITATION OF EXCLUSION OF LIABILITY FOR INDIRECT, SPECIAL,
              INCIDENTAL, OR CONSEQUENTIAL DAMAGES SO THE ABOVE LIMITATION OR
              EXCLUSION MAY NOT APPLY TO YOU. IN ADDITION, IN NO EVENT WILL
              HEALTHLOQ'S CUMULATIVE OR AGGREGATE LIABILITY TO YOU OR ANY OTHER
              PARTY FOR DAMAGES OF ANY KIND OR NATURE IN CONNECTION WITH THE
              SERVICES OR OTHERWISE UNDER THIS AGREEMENT EXCEED THE AMOUNT OF
              FEES PAID UNDER THIS AGREEMENT
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              Here, we essentially state that HealthLOQ can't be held
              responsible if something goes wrong with your use of our Services.
            </p>
            <br />
            <h1>TERM AND TERMINATION</h1>
            <p>
              The term of this Agreement starts upon Your acceptance of this
              Agreement and continues until it is terminated as provided for in
              the terms of this Section 16. Any termination of this Agreement
              must be by written notice by either party (e-mail is accepted,
              provided that Your notice comes from the e-mail address associated
              with Your Account). You may terminate this Agreement by providing
              at least thirty (30) day's written notice to HealthLOQ. Your
              payment obligations for Services shall continue during such notice
              period. HealthLOQ may suspend or terminate the Services and/or
              thisAgreement for any reason at any time, with or without cause,
              and with or without notice. You may terminate this Agreement and
              Your Account at any time by notifying HealthLOQ of such termin
              ation. Upon the termination of this Agreement for any reason, all
              rights granted to You hereunder will cease, and You must promptly
              purge and remove all Components from Your Environment. Termination
              of any Services includes removal of access to such Services and
              barring of further use of the Services. Termination of all
              Services also includes deletion of Your password and all related
              information, files and data associated with or inside Your
              Account, including Blockchain data. Except as otherwise set forth
              in this Agreement, upon termination of any Service, Your right to
              use such Service will automatically terminate immediately. You
              understand that any termination of Services may involve deletion
              of Account Data associated therewith from Our live databases. We
              will not have any liability whatsoever to You for any suspension
              or termination, including for deletion of Account Data. All
              provisions of this Agreement, which by their nature should
              survive, shall survive termination of Services, including, without
              limitation, ownership provisions, Your payment obligations,
              warranty disclaimer, limitations of liability, indemnities and
              Sections 18 to 23.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              This Agreement kicks-off when you initiate use of our product. If
              you want to terminate this Agreement at any time, send us an email
              and consider it terminated. We have the right (but, of course, no
              intention) to do the same.
            </p>
            <br />
            <h1>PUBLICITY</h1>
            <p>
              You agree that We may include Your name, logo and/or trademarks in
              a list of Our Users or customers, on the Website and/or in other
              promotional materials. You also agree that We may verbally
              reference You as a User of the Services. You may withdraw the
              permissions granted to Us in this Section 17 by emailing a request
              to HealthLOQ.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              We may include your company's logo on a list of our customers
              featured on our website and/or in promotional materials. If you're
              not cool with that, just shoot us an email and we'll make sure
              your logo is omitted.
            </p>
            <br />
            <h1>NOTICE</h1>
            <p>
              Where We require that You provide an email address, You are
              responsible for providing Us with Your most current email address.
              In the event that the last email address You provided to Us is not
              valid, or for any reason is not capable of delivering to You any
              notices required/permitted by this Agreement, Our dispatch of the
              e-mail containing such notice will nonetheless constitute
              effective notice. You consent to receive all communications
              including notices, agreements, disclosures, or other information
              from Us electronically. We may provide all such communications by
              e-mail or by posting them on the Services. You must send notices
              of a legal nature to our office headquarters (/contact/). Nothing
              herein shall limit Our right to object to subpoenas, claims, or
              other demands, and We also reserve the right to provide You with
              notices by other means, such as by mail, courier or facsimile.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              This section asks that we communicate with each other
              electronically and points out the importance of maintaining an
              active email address in your HealthLOQ account. For legal matters,
              contact us at the physical address indicated on our website's
              contact page (
              <a href="https://www.HealthLOQ.com/contact/">
                https://www.HealthLOQ.com/contact/
              </a>
              ).
            </p>
            <br />
            <h1>EXPORT CONTROLS</h1>
            <p>
              Components and other software available in connection with the
              Services (the "Software") may be further subject to United States
              import and/or export controls. No Software may be downloaded from
              the Services or otherwise exported or re-exported in violation of
              U.S. export laws. Downloading or using the Software is at your
              sole risk and subject to compliance with all Applicable Laws.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              There's a tiny chance your use of our product could constitute a
              violation of the export laws of whatever country in which you
              operate. You're responsible for compliance with the import and
              export laws of any country in which you use our product.
            </p>
            <br />
            <h1>COMPLIANCE WITH LAWS</h1>
            <p>
              HealthLOQ reserves the right to fully cooperate with any law
              enforcement authorities or court order requesting or directing
              HealthLOQ to disclose the identity and information of anyone
              posting any e-mail or other messages, or publishing or otherwise
              making available any Content or Your Content that is believed or
              alleged (reasonably or not) to violate this Agreement or any
              Applicable Law. You acknowledge and agree that HealthLOQ may
              investigate any violations of law and may cooperate with law
              enforcement authorities in this regard.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              We intend to fully comply with the laws of whatever legal
              jurisdictions in which we or our customers operate. Know that we
              will comply with law enforcement authorities or court orders that
              direct us to reveal the identities of our customers or disclose
              the nature of the content they created through use of our product.
            </p>
            <br />
            <h1>SECURITY INCIDENT REPORTING AND COOPERATION</h1>
            <p>
              In the event that HealthLOQ reasonably suspects or becomes
              aware of an occurrence of unauthorized access, acquisition,
              disclosure, or use of the other Party's Confidential Data
              or Personally Identifiable Information while in the possession of or
              under the control of the HealthLOQ
              (each such occurrence, a “Security Incident”), HealthLOQ shall, to the extent permitted by law,
              promptly and without undue delay notify the other parties in writing at
              the email address provided herein, after reasonably suspecting or becoming aware of such Security Incident.
              Such notification shall include, at a minimum, and to the extent
              known following a reasonable inquiry carried out in accordance with
              the HealthLOQ's internal incident response procedures, all information on
              (i) the extent and nature of the Security Incident, including the categories
              and volume of affected Confidential Data or Personally Identifiable Information
              and the Information Systems involved, (ii) the estimated risks and likely consequences of the
              Security Incident to each Party, and (iii) the investigative, corrective, and remedial actions
              taken, planned, or proposed to prevent, contain, mitigate, and remediate the Security Incident.
            </p>
            <h1>GOVERNING LAW</h1>
            <p>
              This Agreement shall be construed in accordance with and governed
              by the laws of the state of Arizona and the laws of the United
              States applicable in that state without regards to conflict of
              laws principles. The parties hereby expressly and irrevocably
              attorn to the exclusive jurisdiction of the courts in the State of
              Arizona. The United Nations Convention on Contracts for the
              International Sale of Goods (also called the Vienna Convention,
              and which is cited in the statutes of the United States as the
              International Sales of Goods Contracts Convention Act) will not
              apply to this Agreement or the transactions contemplated by this
              Agreement.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              We operate in the State of Arizona, USA, so it only makes sense
              that the governing law of this Agreement be the State of Arizona,
              USA..
            </p>
            <br />
            <h1>MISCELLANEOUS</h1>
            <p>
              This Agreement (and the documents referred to herein, including,
              without limitation, Other Terms and Additional Terms) constitute
              the entire agreement between You and Us in relation to the use of
              the Services, and replace and extinguish all prior agreements,
              arrangements or undertakings of any nature made by the parties,
              whether oral or written, in relation to such subject matter. The
              division of this Agreement into sections and subsections and the
              insertion of headings are for convenience of reference only and
              shall not affect the construction or interpretation of this
              Agreement. If any provision of this Agreement is held by a court
              of competent jurisdiction to be contrary to law, the provision
              shall be modified by the court and interpreted so as best to
              accomplish the objectives of the original provision to the fullest
              extent permitted by law, and the remaining provisions of this
              Agreement shall remain in effect. The failure of HealthLOQ to
              exercise or enforce any right or provision of this Agreement shall
              not operate as a waiver of such right or provision. You may not
              transfer or assign this Agreement or any rights or obligations
              hereunder, directly or indirectly, by operation of law or
              otherwise, without the prior written consent of HealthLOQ and any
              such assignment shall be null and void from the beginning. This
              Agreement shall inure to the benefit of and be binding upon
              HealthLOQ's or Your respective permitted successors and assigns.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              This is where we cover off anything that didn't obviously fit in
              another section. It includes an "entire agreement" declaration,
              which says this agreement supersedes any other agreements into
              which we might have entered.
            </p>
            <br />
            <h1>THIRD PARTY AGREEMENT</h1>
            <p>
              If You have been directed to this Agreement because you are
              purchasing a product or service from someone other than HealthLOQ
              (the "Third Party Vendor") that includes services from HealthLOQ,
              the following provisions will apply to you: (a) Any terms of this
              Agreement relating to Your payment obligations to HealthLOQ shall
              be deemed superseded by the terms of Your agreement with the Third
              Party Vendor. (b) HealthLOQ has no support obligations or
              liability directly to You. Any support obligations or liability
              shall solely be those offered to You by the Third Party Vendor.
              (c) You are subject to all terms and conditions imposed by the
              Third Party Vendor in relation to Your use of the products or
              services of the Third Party Vendor (the "Third Party Items")
              and/or your use of HealthLOQ Services in conjunction with the
              Third Party Items, as applicable. Such Third Party Vendor may
              impose additional restrictions, or may have additional rights, in
              relation to your use of the HealthLOQ Services in conjunction with
              the Third Party Items; it is solely your responsibility to ensure
              that you are aware of and comply with the terms and conditions
              imposed by the Third Party Vendor.To the limited extent that this
              Agreement is less restrictive than the usage rules or terms and
              conditions of the Third Party Vendor, or is in direct conflict
              with, Your agreement with the Third Party Vendor, solely as they
              relate to your obligations to the Third Party Vendor or the Third
              Party Items, Your agreement with the Third Party Vendor shall
              prevail. (d) You acknowledge that Your agreement with the Third
              Party Vendor is concluded solely between you and the Third Party
              Vendor, and not with HealthLOQ, and HealthLOQ shall have no
              responsibility whatsoever respecting the Third Party Items
              (including without limitation in relation to the sale,
              distribution or use thereof, or the performance or non-performance
              thereof). HealthLOQ is responsible only for the HealthLOQ
              Services. (e) To the extent necessary to enable HealthLOQ to
              enforce the provisions of this Agreement respecting Your usage of
              the HealthLOQ Services, HealthLOQ shall be deemed a third party
              beneficiary of Your agreement with the Third Party Vendor, and
              will have the right to enforce the terms of Your agreement with
              the Third Party Vendor against you as a third party beneficiary
              thereof. If you have questions about our Terms of Service, feel
              free to contact us.
            </p>
            <p className="mb-0">In other words,</p>
            <p>
              If you subscribe to HealthLOQ through a third party, your service
              agreement with that third party is the one that legally applies.
            </p>
            <br />
            <h1>Contact Us</h1>
            <p>
              If you have any questions about these Terms and Conditions, You
              can contact us:
            </p>
            <ul>
              <li>
                <strong>By email</strong>:&nbsp;
                <a href="mailto:email:contact@healthloq.com">
                  contact@healthloq.com
                </a>
              </li>
              <li>
                <strong>By phone number</strong>:&nbsp;
                <a
                  href="tel:602.615.7622"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  602.615.7622
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Body>
);
export default TermsAndConditions;
